import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';


const Title4 = styled.span`
  font-family: system-ui;
  color: white;
  font-weight: 500;
  font-size:48px;
  text-align: left;
  `

const Title4md = styled.span`
  font-family: system-ui;
  color: white;
  font-weight: 500;
  font-size:30px;
  text-align: left;
  `

const Title4sm = styled.span`
  font-family: system-ui;
  color: white;
  font-weight: 500;
  font-size:30px;
  text-align: left;
  `


const Title5 = styled.span`
  font-family: system-ui;
  color: white;
  text-align: left;
`




const useStyles = makeStyles((theme) => ({
    sectionLarge: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        },
    },
    sectionMedium: {
        display: 'none',
        [theme.breakpoints.between('md', 'lg')]: {
            display: 'flex',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));



export default function Manage({dynamicLabels}) {
    const classes = useStyles();

    const appLinkClick = () => {
        window.location.href = "https://play.google.com/store/apps/details?id=com.app.medhistorymadeeasy";
      }
    
      const appleLinkClick = () => {
        window.location.href = "https://apps.apple.com/in/app/medhistorymadeeasy/id1576337630";
      }

    return (
        <div id='manage'>
            <Box>
                <div className="bottom-background-color">
                    <Grid container spacing={3} className={classes.sectionLarge}>
                        <div className="display-large">
                            <Grid item xs={6}>
                                <Box paddingLeft={23} paddingTop={8}>
                                    <Title4>
                                        <Grid container spacing={3}>
                                            <Grid item xs={9}>
                                                {dynamicLabels?.manage?.titleText}
                                            </Grid>
                                        </Grid>
                                    </Title4>
                                </Box>
                                <Box paddingLeft={23} paddingTop={2} paddingBottom={8}>
                                    <Title5>
                                        <Grid container spacing={3}>
                                            <Grid item xs={10}>
                                                {dynamicLabels?.manage?.middleText}
                                            </Grid>
                                            <Grid item xs={10}></Grid>
                                            <Grid item xs={10}>
                                                <b>{dynamicLabels?.manage?.appText}</b>
                                            </Grid>
                                            <Box marginLeft={1}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                    <div className="app-link" onClick={appLinkClick}>
                                                        <img src="/images/android.png" alt="goggle play"/>
                                                    </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                    <div className="app-link" onClick={appleLinkClick}>
                                                        <img src="/images/apple.png" alt="app store"/>
                                                    </div>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Title5>
                                </Box>
                            </Grid>
                            <Grid item xs={6} style={{ padding: 0 }}>
                                <img src="images/group.png" width={560} alt="app store" />
                            </Grid>
                        </div>
                        <div className="display-medium-md">
                            <Grid item xs={6}>
                                <Box paddingLeft={23} paddingTop={8}>
                                    <Title4>
                                        <Grid container spacing={3} style={ { fontSize: 44}}>
                                            <Grid item xs={9}>
                                                {dynamicLabels?.manage?.titleText}
                                            </Grid>
                                        </Grid>
                                    </Title4>
                                </Box>
                                <Box paddingLeft={23} paddingTop={2} paddingBottom={5}>
                                    <Title5>
                                        <Grid container spacing={3}>
                                            <Grid item xs={10} style={{ fontSize: 15}}>
                                                {dynamicLabels?.manage?.middleText}
                                            </Grid>
                                            <Grid item xs={10}></Grid>
                                            <Grid item xs={10}>
                                                <b>{dynamicLabels?.manage?.appText}</b>
                                            </Grid>
                                            <Box marginLeft={1}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                    <div className="app-link" onClick={appLinkClick}>
                                                        <img src="/images/android.png" width={200} alt="goggle play"/>
                                                    </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                    <div className="app-link" onClick={appleLinkClick}>
                                                        <img src="/images/apple.png" width={200} alt="app store"/>
                                                    </div>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Title5>
                                </Box>
                            </Grid>
                            <Grid item xs={6} style={{ padding: 0 }}>
                                <img src="images/group.png" width={500} alt="app store" />
                            </Grid>
                        </div>
                        <div className="display-medium">
                            <Grid item xs={6}>
                                <Box paddingLeft={23} paddingTop={8}>
                                    <Title4>
                                        <Grid container spacing={3} style={ { fontSize: 38}}>
                                            <Grid item xs={9}>
                                                {dynamicLabels?.manage?.titleText}
                                            </Grid>
                                        </Grid>
                                    </Title4>
                                </Box>
                                <Box paddingLeft={23} paddingTop={2} paddingBottom={5}>
                                    <Title5>
                                        <Grid container spacing={3}>
                                            <Grid item xs={10} style={{ fontSize: 15}}>
                                                {dynamicLabels?.manage?.middleText}
                                            </Grid>
                                            <Grid item xs={10}></Grid>
                                            <Grid item xs={10}>
                                                <b>{dynamicLabels?.manage?.appText}</b>
                                            </Grid>
                                            <Box marginLeft={1}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                    <div className="app-link" onClick={appLinkClick}>
                                                        <img src="/images/android.png" width={200} alt="goggle play"/>
                                                    </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                    <div className="app-link" onClick={appleLinkClick}>
                                                        <img src="/images/apple.png" width={200} alt="app store"/>
                                                    </div>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Title5>
                                </Box>
                            </Grid>
                            <Grid item xs={6} style={{ padding: 0 }}>
                                <img src="images/group.png" width={500} alt="app store" />
                            </Grid>
                        </div>
                    </Grid>
                    <Grid container spacing={3} className={classes.sectionMedium}>
                        <Grid item md={6}>
                            <Box paddingLeft={18} paddingTop={8}>
                                <Title4md>
                                    <Grid container spacing={3}>
                                        <Grid item xs={10}>
                                            {dynamicLabels?.manage?.titleText}
                                        </Grid>
                                    </Grid>
                                </Title4md>
                            </Box>
                            <Box paddingLeft={18} paddingTop={2} paddingBottom={8}>
                                <Title5 style={{ fontSize: 15 }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={10}>
                                            {dynamicLabels?.manage?.middleText}
                                        </Grid>
                                        <Grid item xs={10}></Grid>
                                        <Grid item xs={10}>
                                            <b>{dynamicLabels?.manage?.appText}</b>
                                        </Grid>
                                        <Box marginTop={2} marginLeft={1}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6}>
                                                <div className="app-link" onClick={appLinkClick}>
                                                    <img src="/images/android.png" width={145} alt="goggle play" />
                                                </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                <div className="app-link" onClick={appleLinkClick}>
                                                    <img src="/images/apple.png" width={145} alt="app store" />
                                                </div>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Title5>
                            </Box>
                        </Grid>
                        <Grid item md={6} style={{ padding: 0 }}>
                            <img src="images/group.png" width={485} alt="app store" />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.sectionMobile}>
                        <Grid item xs={12}>
                            <Box paddingLeft={8} paddingTop={8}>
                                <Title4sm>
                                    <Grid container spacing={3}>
                                        <Grid item xs={8}>
                                            {dynamicLabels?.manage?.titleText}
                                        </Grid>
                                    </Grid>
                                </Title4sm>
                            </Box>
                            <Box paddingLeft={8} paddingTop={2} paddingBottom={8}>
                                <Title5 style={{ fontSize: 15 }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={10}>
                                            {dynamicLabels?.manage?.middleText}
                                        </Grid>
                                        <Grid item xs={10}></Grid>
                                        <Grid item xs={12}>
                                            <b>{dynamicLabels?.manage?.appText}</b>
                                        </Grid>
                                        <Box marginTop={2} marginLeft={1}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6}>
                                                <div className="app-link" onClick={appLinkClick}>
                                                    <img src="/images/android.png" width={100} alt="goggle play" />
                                                </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                <div className="app-link" onClick={appleLinkClick}>
                                                    <img src="/images/apple.png" width={100} alt="app store" />
                                                </div>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Title5>
                            </Box>
                        </Grid>
                        <Grid item xs={12} style={{ padding: 0 }}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <img width={500} src="images/group.png" alt="app store" />
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </div>
    )
}