import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const Title = styled.span`
font-weight: bolder;
color: grey;
font-size: 16px;
`

const Titlemd = styled.span`
font-weight: bolder;
color: grey;
font-size: 15px;
`

const Titlesm = styled.span`
font-weight: bolder;
color: grey;
font-size: 14px;
`

const Title2 = styled.span`
font-weight: bolder;
font-size: 32px;
`

const Title2md = styled.span`
font-weight: bolder;
font-size: 28px;
`

const Title2sm = styled.span`
font-weight: bolder;
font-size: 22px;
`

const Title3 = styled.span`
text-align: left;
font-size: 1.2vw ;
font-family: Montserrat-Regular !important;
font-weight:366.5;
`

const Title3lg = styled.span`
text-align: left;
font-size: 1.07vw ;
font-family: Montserrat-Regular !important;
font-weight:366.5;
`



const useStyles = makeStyles((theme) => ({
    sectionLarge: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        },
    },
    sectionMedium: {
      display: 'none',
      [theme.breakpoints.between('md','lg')]: {
          display: 'block',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
    },
    },
    sectionMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function About({dynamicLabels}) {
    const classes = useStyles();

    return (
        <div id="about">
            <Box marginTop={3} paddingTop={13} paddingBottom={8} className={classes.sectionLarge}>
                <Grid container spacing={3} alignItems="center" justify="center">
                    <Grid item xs={12}>
                        <Title>{dynamicLabels?.aboutMed?.topText}</Title>
                    </Grid>
                    <Grid item xs={12}>
                        <Title2>{dynamicLabels?.aboutMed?.titleText}</Title2>
                    </Grid>
                </Grid>
                <Box marginTop={7} paddingLeft={22} className='display-large'>
                    <Title3lg className='new-font'>
                        <Grid container spacing={3} alignItems="center" justify="center">
                            <Grid item xs={4}>
                                <Box marginLeft={10}>
                                    <img src="/images/Mask.png" alt="med history" height={500} width={380} />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <Box paddingLeft={5} paddingRight={46}>
                                    <p>{dynamicLabels?.aboutMed?.mainTextP1}</p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP2}
                           </p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP3}
                            </p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP4}
                            </p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP5}
                            </p>

                                </Box>
                            </Grid>
                        </Grid>
                    </Title3lg>
                </Box>
                <Box marginTop={13} paddingLeft={23} className='display-medium-md'>
                    <Title3>
                        <Grid container spacing={3} alignItems="center" justify="center">
                            <Grid item xs={4}>
                                <img src="/images/Mask.png" alt="med history" height={540} width={400} />
                            </Grid>
                            <Grid item xs={8}>
                                <Box paddingLeft={8} paddingRight={29}>
                                    <p>{dynamicLabels?.aboutMed?.mainTextP1}</p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP2}
                                    </p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP3}
                                    </p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP4}
                                    </p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP5}
                                    </p>
                                </Box>
                            </Grid>
                        </Grid>
                    </Title3>
                </Box>
                <Box marginTop={13} paddingLeft={23} className='display-medium'>
                    <Title3>
                        <Grid container spacing={3} alignItems="center" justify="center">
                            <Grid item xs={4}>
                                <img src="/images/Mask.png" alt="med history" height={480} width={350} />
                            </Grid>
                            <Grid item xs={8}>
                                <Box paddingLeft={6} paddingRight={25}>
                                    <p>{dynamicLabels?.aboutMed?.mainTextP1}</p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP2}
                                    </p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP3}
                                    </p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP4}
                                    </p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP5}
                                    </p>
                                </Box>
                            </Grid>
                        </Grid>
                    </Title3>
                </Box>
            </Box>
            <Box marginTop={0} paddingTop={13} paddingBottom={8} className={classes.sectionMedium}>
                <Grid container spacing={3} alignItems="center" justify="center">
                    <Grid item xs={12}>
                        <Titlemd>{dynamicLabels?.aboutMed?.topText}</Titlemd>
                    </Grid>
                    <Grid item xs={12}>
                        <Title2md>{dynamicLabels?.aboutMed?.titleText}</Title2md>
                    </Grid>
                </Grid>
                <Box marginTop={7} paddingLeft={12}>
                    <Title3>
                        <Grid container spacing={3} alignItems="center" justify="center">
                            <Grid item xs={4}>
                                <img src="/images/Mask.png" alt="med history" height={445} width={340} />
                            </Grid>
                            <Grid item xs={8}>
                                <Box paddingLeft={6} paddingRight={25} style={{ fontSize: 15}}>
                                    <p>{dynamicLabels?.aboutMed?.mainTextP1}</p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP2}
                                    </p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP3}
                                    </p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP4}
                                    </p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP5}
                                    </p>
                                </Box>
                            </Grid>
                        </Grid>
                    </Title3>
                </Box>
            </Box>
            <Box marginTop={0} paddingTop={10} paddingBottom={8} className={classes.sectionMobile}>
                <Grid container spacing={3} alignItems="center" justify="center">
                    <Grid item xs={12}>
                        <Titlesm>{dynamicLabels?.aboutMed?.topText}</Titlesm>
                    </Grid>
                    <Grid item xs={12}>
                        <Title2sm>{dynamicLabels?.aboutMed?.titleText}</Title2sm>
                    </Grid>
                </Grid>
                <Box marginTop={7} paddingLeft={0}>
                    <Title3>
                        <Grid container spacing={3} alignItems="center" justify="center">
                            <Grid item xs={12}>
                            <Box marginTop={-5}
                                          display="flex"
                                            justifyContent="center"
                                              alignItems="center"
                                                                >
                                <img src="/images/Mask.png" alt="med history" width={250} />
                                        </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box paddingLeft={6} paddingRight={6} style={{ fontSize: 14}}>
                                    <p>{dynamicLabels?.aboutMed?.mainTextP1}</p>
                                    <br />
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP2}
                                    </p>
                                    <br />
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP3}
                                    </p>
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP4}
                                    </p>
                                    <br />
                                    <p>
                                        {dynamicLabels?.aboutMed?.mainTextP5}
                                    </p>
                                </Box>
                            </Grid>
                        </Grid>
                    </Title3>
                </Box>
            </Box>
        </div>
    )
}