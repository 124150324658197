import React from 'react';
import {fade, withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';
import Box from '@material-ui/core/Box'
import styled from 'styled-components';
import {HashLink} from "react-router-hash-link";
import englishLabels from "../i18n/en";
import spanishLabels from "../i18n/sp";

const Title = styled.span`
font-family: Montserrat-Regular !important;
color: rgb(51,51,51) !important;
font-size:15px;
font-weight:600;
`

const Titled= styled.span `
font-family: Montserrat-Regular !important;
font-size: 14.5px;
`

let imgUrl = 'images/logo.png';
let flag = englishLabels?.flag;

const useStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        flexGrow: 1,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
});
var dynamicLabels;

export class PrimarySearchAppBar extends React.Component{
    constructor(props) {
        super(props);

        dynamicLabels = this.props.dynamicLabels;



        this.state = {
            country : {
                region: 'English',
                country_code: 'EN'
            },
            anchorEl : null,
            mobileMoreAnchorEl: null,
        }
    }

    handleChange = (e) => {
        this.setState({
            country : e
        })

        if(e.country_code === 'EN') {
            // changeLanguage('EN')
            this.props.handleLanguage('EN')
            dynamicLabels = englishLabels;
            imgUrl = 'images/logo.png';
            flag = englishLabels?.flag;
        } else if( e.country_code === 'SP') {
            // changeLanguage('SP')
            this.props.handleLanguage('SP')
            dynamicLabels = spanishLabels;
            imgUrl = 'images/spanish-logo.png'
            flag = spanishLabels?.flag;
        }
    }

    render() {

        const {classes} = this.props;

        const data = [
            {
                region: 'English',
                country_code: 'EN'
            },
            {
                region: 'Spanish',
                country_code: 'SP'
            }
        ]

        const setAnchorEl = (param) => {
            this.setState( {
                anchorEl: param
            })
        }
        // const [anchorEl, setAnchorEl] = React.useState(null);

        const setMobileMoreAnchorEl = (param) => {
            this.setState({
                mobileMoreAnchorEl: param
            })
        }
        // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

        const isMenuOpen = Boolean(this.state.anchorEl);
        const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);

        const handleMobileMenuClose = () => {
            setMobileMoreAnchorEl(null);
        };

        const handleMenuClose = () => {
            setAnchorEl(null);
            handleMobileMenuClose();
        };

        const handleMobileMenuOpen = (event) => {
            setMobileMoreAnchorEl(event.currentTarget);
        };

        const returnFlag = () =>{
            if(flag?.includes('spain')) {
                return <>
                    <Box paddingRight={8} marginTop={1.2}>
                        <img src={flag} width={30} alt="" />
                    </Box>
                </>
            } else {
                return <>
                    <Box paddingRight={8} marginTop={0.6}>
                        <img src={flag} width={30} alt="" />
                    </Box>
                </>
            }
        }

        const menuId = 'primary-search-account-menu';
        const renderMenu = (
            <Menu
                anchorEl={this.state.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
                <MenuItem onClick={handleMenuClose}>My account</MenuItem>
            </Menu>
        );

        const mobileMenuId = 'primary-search-account-menu-mobile';
        const renderMobileMenu = (
            <Menu
                anchorEl={this.state.mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}
            >
                <MenuItem>
                    <HashLink to="/#home"><Title>{dynamicLabels?.navBar?.home}</Title></HashLink>
                </MenuItem>
                <MenuItem>
                    <HashLink to="/#about"><Title>{dynamicLabels?.navBar?.about}</Title></HashLink>
                </MenuItem>
                {/*<MenuItem>*/}
                {/*    <HashLink to="#works"><Title>{dynamicLabels?.navBar?.works}</Title></HashLink>*/}
                {/*</MenuItem>*/}
                {/*<MenuItem>*/}
                {/*    <Title>{dynamicLabels?.navBar?.tutorial}</Title>*/}
                {/*</MenuItem>*/}
                <MenuItem>
                    <HashLink to="/#contact"><Title>{dynamicLabels?.navBar?.contact}</Title></HashLink>
                </MenuItem>

            </Menu>
        );

        // const classes = this.useStyles();

        return (
            <div className={classes.grow}>
                <AppBar position="fixed" className="nav-bar">
                    <div className={classes.root}>
                        <Grid container spacing={3} alignItems="center" justify="center">
                            <Grid item lg={2} md={2} xs={2} className={classes.sectionDesktop}>
                                <img src={imgUrl} alt="med history" />
                            </Grid>
                            <Grid item xs={3} className={classes.sectionMobile}>
                                <img src={imgUrl} width={120} alt="med history" />
                            </Grid>
                            <Grid item lg={8} md={10} xs={8} className={classes.sectionDesktop}>
                                <Toolbar>
                                    <Typography className={classes.title}>
                                        <HashLink smooth to="/#home"><Title>{dynamicLabels?.navBar?.home}</Title></HashLink>
                                    </Typography>
                                    <Typography className={classes.title}>
                                        <HashLink smooth to="/#about"><Title>{dynamicLabels?.navBar?.about}</Title></HashLink>
                                    </Typography>
                                    {/*<Typography className={classes.title}>*/}
                                    {/*    <HashLink smooth to="#works"><Title>{dynamicLabels?.navBar?.works}</Title></HashLink>*/}
                                    {/*</Typography>*/}
                                    {/*<Typography className={classes.title}>*/}
                                    {/*    <Title>{dynamicLabels?.navBar?.tutorial}</Title>*/}
                                    {/*</Typography>*/}
                                    <Typography className={classes.title}>
                                        <HashLink smooth to="/#contact"><Title>{dynamicLabels?.navBar?.contact}</Title></HashLink>
                                    </Typography>
                                    <Typography className={classes.title}>
                                        <Titled>
                                            <Grid container spacing={0}>
                                                <Grid item xs={3}></Grid>
                                                <Grid item xs={2}>
                                                    {returnFlag()}
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Select
                                                        placeholder="Select Language"
                                                        value={this.state.country}
                                                        options={data}
                                                        onChange={this.handleChange}
                                                        getOptionLabel={x => x.region}
                                                        getOptionValue={x => x.country_code}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Titled>
                                    </Typography>
                                </Toolbar>
                            </Grid>
                            <Grid item xs={2} className={classes.sectionMobile}></Grid>
                            <Grid item xs={5} className={classes.sectionMobile}>
                                <Select
                                    placeholder="Select Language"
                                    value={this.state.country}
                                    style={{width: 100}}
                                    options={data}
                                    onChange={this.handleChange}
                                    getOptionLabel={x => x.region}
                                    getOptionValue={x => x.country_code}
                                />
                            </Grid>
                            <Grid item xs={2} className={classes.sectionMobile}>
                                <IconButton
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="inherit"
                                >
                                    <MoreIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
            </div>
        );
    }
}

export default withStyles(useStyles)(PrimarySearchAppBar)