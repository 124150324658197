import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { HashLink } from 'react-router-hash-link';

const Title = styled.span`
font-weight: bolder;
color: grey;
font-size: 16px;
`

const Titlemd = styled.span`
font-weight: bolder;
color: grey;
font-size: 15px;
`

const Titlesm = styled.span`
font-weight: bolder;
color: grey;
font-size: 14px;
`

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    sectionLarge: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        },
    },
    sectionMedium: {
        display: 'none',
        [theme.breakpoints.between('md', 'lg')]: {
            display: 'block',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    sectionMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function Footer({dynamicLabels}) {
    const classes = useStyles();

    return (
        <div className={classes.root} id='footer'>
            <AppBar position="static" className="nav-bar">
                <Box marginBottom={2} className={classes.sectionLarge} marginRight={3} marginLeft={3}>
                    <div className={classes.root}>
                        <Grid container spacing={3} justify="center">
                            <Grid item xs={3}>
                                <img src={dynamicLabels?.logo} alt="med history" />
                            </Grid>
                            <Grid item xs={6}>
                                <Toolbar>
                                    <Typography className={classes.title}>
                                        <Title>{dynamicLabels?.footer?.copyright}</Title>
                                        <Box padding={2}>
                                            <Grid container spacing={3} justify="center" alignContent='center'>
                                                <Grid item xs={4}>
                                                    <HashLink to='privacy-policy' style={{ cursor: 'pointer'}}>{dynamicLabels?.footer?.privacy}</HashLink>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <HashLink to='terms-and-conditions' style={{ cursor: 'pointer'}}>{dynamicLabels?.footer?.terms}</HashLink>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Typography>
                                </Toolbar>
                            </Grid>
                            <Grid item xs={2}>
                                <Toolbar>
                                    <Typography className={classes.title}>
                                        <Title><img src="/images/insta.svg" alt="med history" /></Title>
                                    </Typography>
                                    <Typography className={classes.title}>
                                        <Title><img src="/images/drib.svg" alt="med history" /></Title>
                                    </Typography>
                                    <Typography className={classes.title}>
                                        <Title><img src="/images/twitter.svg" alt="med history" /></Title>
                                    </Typography>
                                    <Typography className={classes.title}>
                                        <Title><img src="/images/youtube.svg" alt="med history" /></Title>
                                    </Typography>
                                </Toolbar>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
                <Box marginBottom={2} className={classes.sectionMedium}>
                    <div className={classes.root}>
                        <Grid container spacing={3} alignItems="center" justify="center">
                            <Grid item xs={3}>
                                <img src={dynamicLabels?.logo} alt="med history" />
                            </Grid>
                            <Grid item xs={6}>
                                <Toolbar>
                                    <Typography className={classes.title}>
                                        <Titlemd>{dynamicLabels?.footer?.copyright}</Titlemd>
                                        <Box padding={2}>
                                            <Grid container spacing={3} justify="center" alignContent='center'>
                                                <Grid item xs={4}>
                                                    <HashLink to='privacy-policy' style={{ cursor: 'pointer'}}>{dynamicLabels?.footer?.privacy}</HashLink>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <HashLink to='terms-and-conditions' style={{ cursor: 'pointer'}}>{dynamicLabels?.footer?.terms}</HashLink>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Typography>
                                </Toolbar>
                            </Grid>
                            <Grid item xs={3}>
                                <Toolbar>
                                    <Typography className={classes.title}>
                                        <Titlemd><img src="/images/insta.svg" alt="med history" /></Titlemd>
                                    </Typography>
                                    <Typography className={classes.title}>
                                        <Titlemd><img src="/images/drib.svg" alt="med history" /></Titlemd>
                                    </Typography>
                                    <Typography className={classes.title}>
                                        <Titlemd><img src="/images/twitter.svg" alt="med history" /></Titlemd>
                                    </Typography>
                                    <Typography className={classes.title}>
                                        <Titlemd><img src="/images/youtube.svg" alt="med history" /></Titlemd>
                                    </Typography>
                                </Toolbar>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
                <Box marginBottom={2} marginTop={3} className={classes.sectionMobile}>
                    <div className={classes.root}>
                        <Grid container spacing={3} alignItems="center" justify="center">
                            <Grid item xs={12}>
                                <img src={dynamicLabels?.logo} alt="med history" />
                            </Grid>
                            <Grid item xs={12}>
                                <Toolbar>
                                    <Typography className={classes.title}>
                                        <Titlesm>{dynamicLabels?.footer?.copyright}</Titlesm>
                                        <Box padding={2}>
                                            <Grid container spacing={3} justify="center" alignContent='center'>
                                                <Grid item xs={6}>
                                                    <HashLink to='privacy-policy' style={{ cursor: 'pointer'}}>{dynamicLabels?.footer?.privacy}</HashLink>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <HashLink to='terms-and-conditions' style={{ cursor: 'pointer'}}>{dynamicLabels?.footer?.terms}</HashLink>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Typography>
                                </Toolbar>
                            </Grid>
                            <Grid item xs={12}>
                                <Toolbar>
                                    <Typography className={classes.title}>
                                        <Title><img src="/images/insta.svg" alt="med history" /></Title>
                                    </Typography>
                                    <Typography className={classes.title}>
                                        <Title><img src="/images/drib.svg" alt="med history" /></Title>
                                    </Typography>
                                    <Typography className={classes.title}>
                                        <Title><img src="/images/twitter.svg" alt="med history" /></Title>
                                    </Typography>
                                    <Typography className={classes.title}>
                                        <Title><img src="/images/youtube.svg" alt="med history" /></Title>
                                    </Typography>
                                </Toolbar>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </AppBar>
        </div>
    )
}