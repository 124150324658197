import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react'

const Title = styled.span`
font-weight: bolder;
color: grey;
font-size: 17px;
`

const Titlemd = styled.span`
font-weight: bolder;
color: grey;
font-size: 15px;
`

const Titlesm = styled.span`
font-weight: bolder;
color: grey;
font-size: 14px;
`
const Title6 = styled.span`
font-weight: bolder;
font-size: 45px;
text-align: left;
font-weight:medium;
`

const Title6md = styled.span`
font-weight: bolder;
font-size: 33px;
text-align: left;
`

const Title6sm = styled.span`
font-weight: bolder;
font-size: 25px;
text-align: left;
`


const useStyles = makeStyles((theme) => ({
    sectionLarge: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        },
    },
    sectionMedium: {
        display: 'none',
        [theme.breakpoints.between('md', 'lg')]: {
            display: 'block',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    sectionMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));


export default function Contact({ dynamicLabels }) {
    const classes = useStyles();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [password, setPassword] = useState('');
    const handleNameChange = (e) => setName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleMessageChange = (e) => setMessage(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    const [show, setShow] = useState(false);

    // const openModel = () => {
    //     setShow(true);
    // }

    const closeModel = () => {
        setShow(false);
    }

    const sendEmail = () => {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(name && email && message) {
            if(!email.match(validRegex)) {
                alert('please provide valid email address !');
                return;
            }
            window.sendEmail(name, email, message);
        } else {
            alert('please fill all the details first !!');
        }
    }

    const conformPassword = () => {
        if(!password) {
            alert('please provide password');
            return;
        }
        closeModel();
    } 


    return (
        <div id='contact'>
            <Box paddingLeft={20} paddingTop={13} paddingBottom={12} className={classes.sectionLarge}>
                <div className="display-large">
                    <Box boxShadow={4} paddingLeft={10} paddingTop={8} paddingBottom={8} marginRight={18}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} className="align-left-content">
                                <Title6>{dynamicLabels?.contact?.titleText}</Title6>
                            </Grid>
                            <Grid item xs={12} className="align-left-content">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={8} className="align-left-content">
                                        <Title>{dynamicLabels?.contact?.middleText}</Title>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={5}>
                                        <Input style={{ fontSize: 17, fontWeight: 'bolder' }} placeholder={dynamicLabels?.contact?.name1} value={name} onChange={handleNameChange} fullWidth></Input>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={5}>
                                        <Input style={{ fontSize: 17, fontWeight: 'bolder' }} placeholder={dynamicLabels?.contact?.name2} value={email} onChange={handleEmailChange} fullWidth></Input>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={11}>
                                        <Input style={{ fontSize: 17, fontWeight: 'bolder' }} placeholder={dynamicLabels?.contact?.message} value={message} onChange={handleMessageChange} fullWidth></Input>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}>
                                        <Button style={{ backgroundColor: 'rgb(0,56,119)', color: '#FFFFFF', borderRadius: 100, paddingLeft: 60, paddingRight: 60, paddingTop: 13, paddingBottom: 13, fontWeight: 566 }} onClick={() => sendEmail()}>{dynamicLabels?.contact?.submit}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*<Grid item xs={4} className="align-left-content">*/}
                            {/*    <img src="images/map.png" alt="app store" />*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Box>
                </div>
                <div className="display-medium-md">
                    <Box boxShadow={4} paddingLeft={6} paddingTop={8} paddingBottom={8} marginRight={17}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} className="align-left-content">
                                <Title6>{dynamicLabels?.contact?.titleText}</Title6>
                            </Grid>
                            <Grid item xs={12} className="align-left-content">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={8} className="align-left-content">
                                        <Title>{dynamicLabels?.contact?.middleText}</Title>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={5}>
                                        <Input style={{ fontSize: 17, fontWeight: 'bolder' }} placeholder={dynamicLabels?.contact?.name1} value={name} onChange={handleNameChange} fullWidth></Input>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={5}>
                                        <Input style={{ fontSize: 17, fontWeight: 'bolder' }} placeholder={dynamicLabels?.contact?.name2} value={email} onChange={handleEmailChange} fullWidth></Input>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={11}>
                                        <Input style={{ fontSize: 17, fontWeight: 'bolder' }} placeholder={dynamicLabels?.contact?.message} value={message} onChange={handleMessageChange} fullWidth></Input>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}>
                                        <Button style={{ backgroundColor: 'rgb(0,56,119)', color: '#FFFFFF', borderRadius: 100, paddingLeft: 60, paddingRight: 60, paddingTop: 13, paddingBottom: 13, fontWeight: 566 }} onClick={() => sendEmail()}>{dynamicLabels?.contact?.submit}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*<Grid item xs={4} className="align-left-content">*/}
                            {/*    <img src="images/map.png" alt="app store" />*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Box>
                </div>
                <div className="display-medium">
                    <Box boxShadow={4} paddingLeft={10} paddingTop={8} paddingBottom={8} marginRight={16}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} className="align-left-content">
                                <Title6 style={{ fontSize: 45 }}>{dynamicLabels?.contact?.titleText}</Title6>
                            </Grid>
                            <Grid item xs={12} className="align-left-content">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={10} className="align-left-content">
                                        <Title style={{ fontSize: 15 }}>{dynamicLabels?.contact?.middleText}</Title>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={5}>
                                        <Input style={{ fontSize: 15, fontWeight: 'bolder' }} placeholder={dynamicLabels?.contact?.name1} value={name} onChange={handleNameChange} fullWidth></Input>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={5}>
                                        <Input style={{ fontSize: 15, fontWeight: 'bolder' }} placeholder={dynamicLabels?.contact?.name2} value={email} onChange={handleEmailChange} fullWidth></Input>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={11}>
                                        <Input style={{ fontSize: 15, fontWeight: 'bolder' }} placeholder={dynamicLabels?.contact?.message} value={message} onChange={handleMessageChange} fullWidth></Input>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}>
                                        <Button style={{ backgroundColor: 'rgb(0,56,119)', color: '#FFFFFF', borderRadius: 100, paddingLeft: 60, paddingRight: 60, paddingTop: 13, paddingBottom: 13, fontWeight: 566 }} onClick={() => sendEmail()}>{dynamicLabels?.contact?.submit}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*<Grid item xs={5} className="align-left-content">*/}
                            {/*    <img src="images/map.png" alt="app store" />*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Box>
                </div>
            </Box>
            <Box marginTop={5} paddingLeft={14} paddingRight={12} paddingTop={12} paddingBottom={10} className={classes.sectionMedium}>
                <Box boxShadow={3} paddingLeft={10} paddingTop={5} paddingBottom={2} marginRight={1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} className="align-left-content">
                            <Title6md>{dynamicLabels?.contact?.titleText}</Title6md>
                        </Grid>
                        <Grid item xs={12} className="align-left-content">
                            <Grid container spacing={3}>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={8} className="align-left-content">
                                    <Titlemd>{dynamicLabels?.contact?.middleText}</Titlemd>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={5}>
                                    <Input style={{ fontSize: 16 }} placeholder={dynamicLabels?.contact?.name1} value={name} onChange={handleNameChange} fullWidth></Input>
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={5}>
                                    <Input style={{ fontSize: 16 }} placeholder={dynamicLabels?.contact?.name2} value={email} onChange={handleEmailChange} fullWidth></Input>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={11}>
                                    <Input style={{ fontSize: 16 }} placeholder={dynamicLabels?.contact?.message} value={message} onChange={handleMessageChange} fullWidth></Input>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}>
                                    <Button style={{ fontSize: 13, backgroundColor: 'rgb(0,56,119)', color: '#FFFFFF', borderRadius: 100, paddingLeft: 40, paddingRight: 40, paddingTop: 10, paddingBottom: 10, fontWeight: 566 }} onClick={() => sendEmail()}>{dynamicLabels?.contact?.submit}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*<Grid item xs={6} className="align-left-content">*/}
                        {/*    <img src="images/map.png" alt="app store" />*/}
                        {/*</Grid>*/}
                    </Grid>
                </Box>
            </Box>
            <Box paddingLeft={3} paddingRight={3} paddingTop={10} paddingBottom={10} className={classes.sectionMobile}>
                <Box boxShadow={2} paddingLeft={4} paddingTop={3} paddingBottom={3} paddingRight={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className="align-left-content">
                            <Title6sm>{dynamicLabels?.contact?.titleText}</Title6sm>
                        </Grid>
                        <Grid item xs={12} className="align-left-content">
                            <Grid container spacing={3}>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12} className="align-left-content">
                                    <Titlesm>{dynamicLabels?.contact?.middleText} </Titlesm>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={5} style={{ paddingRight: 0 }}>
                                    <Input placeholder={dynamicLabels?.contact?.name1} value={name} onChange={handleNameChange} fullWidth></Input>
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={5} style={{ paddingLeft: 0 }}>
                                    <Input style={{ fontSize: 15 }} placeholder={dynamicLabels?.contact?.name2} value={email} onChange={handleEmailChange} fullWidth></Input>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={11}>
                                    <Input style={{ fontSize: 15 }} placeholder={dynamicLabels?.contact?.message} value={message} onChange={handleMessageChange} fullWidth></Input>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}>
                                    <Button style={{ fontSize: 12, backgroundColor: 'rgb(0,56,119)', color: '#FFFFFF', borderRadius: 100, paddingLeft: 40, paddingRight: 40, paddingTop: 10, paddingBottom: 10 }} onClick={() => sendEmail()}>{dynamicLabels?.contact?.submit}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*<Grid item xs={12} className="align-left-content">*/}
                        {/*    <Box*/}
                        {/*        display="flex"*/}
                        {/*        justifyContent="center"*/}
                        {/*        alignItems="center"*/}
                        {/*        paddingRight={2}*/}
                        {/*        marginTop={3}*/}
                        {/*    >*/}
                        {/*        <img src="images/map.png" alt="app store" />*/}
                        {/*    </Box>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Box>
            </Box>
            {show && 
                <div id="myModal" className="modal" show={show}>
                <div className="modal-content">

                    <div className="modal-header">
                        <span className="close" onClick={closeModel}>&times;</span>
                        <h2>Authentication</h2>
                    </div>

                    <div className="modal-body">
                        <Box padding={5}>
                        <Input type="password" style={{ fontSize: 15 }} placeholder={dynamicLabels?.contact?.password} value={password} onChange={handlePasswordChange} fullWidth></Input>
                        <Box marginTop={3}>
                        <Button style={{ fontSize: 12, backgroundColor: 'rgb(0,56,119)', color: '#FFFFFF', borderRadius: 100, paddingLeft: 40, paddingRight: 40, paddingTop: 10, paddingBottom: 10 }} onClick={() => conformPassword()}>{dynamicLabels?.contact?.submit}</Button>
                        </Box>
                        </Box>
                    </div>
                </div>
            </div>
        }
        </div>
    )
}