export const englishLabels = {
    "logo": 'images/logo.png',
    'flag' : 'images/us.png',
    "navBar" : {
        "home" : "Home",
        "about": "About Us",
        "works": "How it works",
        "tutorial": "Tutorial",
        "contact": "Contact us"
    },
    "firstContent" : {
        "mainTitleLine1": "Simplify,",
        "mainTitleLine2": "and improve your",
        "mainTitleLine3": "record keeping!",
        "middleText": ""
    },
    "aboutMed": {
        "topText": "",
        "titleText": "About MedHistoryMadeEasy",
        "mainTextP1": "This application is designed to be a one stop shop for all of your Medical Records. Compiling Medical Documents, History, Medication, Vaccinations, and more can be extraordinarily cumbersome for patients and their family members. This platform helps simplify medical record keeping for you, your family members, and any future children!",
        "mainTextP2": "Please continue to follow up with your Primary Care Provider for Immediate, Emergent, or Acute related concerns, but lets help you and your provider with your ongoing record keeping.",
        "mainTextP3": "Imagine....You never have to worry about which vaccinations your child has obtained, what medications your grandmother is on prior to the Emergency Room visit, or what the natural course of your babies newborn history was.",
        "mainTextP4": "Should you leave the state, need immediate care in an emergency room, or should you need to obtain a new physician, this shareable tool makes transitioning that much smoother.",
        "mainTextP5": "This resource will not only help you, but help your primary care provider whether old, or newly established, keep your medical history constantly up to date."
    },
    "feauture" : {
        "topText": "APP FEATURE",
        "titleText": "With our super powers we have created this",
        "design1" : {
            "title": "Newborn History",
            "content": "Tired of searching through tons of papers to figure out your newborns medical history? Your frustration stops here. Easily store all of your newborns new medical history with or without the help of your physician to make things easier."
        },
        "design2" : {
            "title": "Appointment History",
            "content": "Never forget when your next Doctor's Appointment is. We'll do the work for you, with our notifications and Appointment History Tab."
        },
        "design3" : {
            "title": "Medical Record Keeping",
            "content": "Concerned over how to recall what vaccines, medical history, medical conditions, or medications you or your family is on? Rest Assured, its always here."
        },
        "design4" : {
            "title": "Sharing capabilities",
            "content": "Want a place to store and share proof of your COVID Vaccinations, and other Medical History? Here's your answer! You can store medical documentation, files, vaccination history, all by the click of your phone."
        }
    },
    "works" : {
        "topText": "HOW IT WORKS ?",
        "titleText": "With our super powers we have reached this",
        "design1" : {
            "title": "Design",
            "content": "Turn your idea from concept to MVP"
        },
        "design2" : {
            "title": "Design",
            "content": "Sketch out the product to align the user needs"
        },
        "design3" : {
            "title": "Design",
            "content": "Convert the designs into a live application"
        },
        "design4" : {
            "title": "Design",
            "content": "Launching the application to the market"
        }
    },
    "manage" : {
        "titleText": "Lets make Record Keeping Easier!",
        "middleText" : "Download the App to make your record keeping easier, faster, and readily available for whenever its needed.",
        "appText": "Get the App"
    },
    "contact" : {
        "titleText": "Contact us",
        "middleText": "Please feel free to contact us for further questions, feedback, or concerns.",
        "name1": "Your Name",
        "name2": "Your Email",
        "message": "Message",
        "password": "Password",
        "submit": "Submit Now"
    },
    "footer": {
        "copyright": "© Copyright 2020 MedHistoryMadeEasy. All rights reserved",
        "privacy": "Privacy Policy",
        "req": "en",
        "terms" : "Terms and Conditions"
    }
}
export default englishLabels;