import { Box } from '@material-ui/core';
import React from 'react';

export class TermsAndConditions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            print: ''
        }
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': this.props.dynamicLabels.footer.req
        }
        const response = await fetch('https://api.medhistorymadeeasy.com/static-page/get/terms-and-conditions', {headers});
        const data = await response.json();
        this.setState({ print: data.data.description })
    }

 


    componentDidUpdate(prevProps) {
        this.getData();
    }

    render() {
        return(
        <div id='terms-and-conditions'>
            <Box padding={5} marginTop={5}>
            <div dangerouslySetInnerHTML={{__html: this.state.print}}/>
            </Box>
        </div>
        )
    }
}