import './App.css';
import About from './modules/About'
import SimpleContainer from './modules/SimpleContainer';
import Feature from './modules/Feature';
import Manage from './modules/Manage'
import Contact from './modules/Contact';
import Footer from './modules/Footer';
import PrimarySearchAppBar from './modules/Res-navbar';
import {englishLabels} from "./i18n/en";
import {spanishLabels} from "./i18n/sp";
import React from "react";
import { PrivacyPolicy } from './modules/Privacy-policy';
import { Route, Switch } from 'react-router';
import { TermsAndConditions } from './modules/Terms-Conditions';


function App() {
    const [label, setLabel] = React.useState(englishLabels);

    const handleLanguage = (e) => {
        if(e === 'EN') {
            setLabel(englishLabels);
        } else if(e === 'SP') {
            setLabel(spanishLabels);
        }
    }
  return (
          <div className="App">
                    <Switch>
                        <Route exact path={'/'}>
                            <PrimarySearchAppBar dynamicLabels={label} handleLanguage={handleLanguage}/>
                            <SimpleContainer dynamicLabels={label}/>
                            <About dynamicLabels={label} />
                            <Feature dynamicLabels={label}/>
                            <Manage dynamicLabels={label}/>
                            <Contact dynamicLabels={label}/>
                            <Footer dynamicLabels={label}/>
                        </Route>
                        <Route path={"/privacy-policy"}>
                            <PrimarySearchAppBar dynamicLabels={label} handleLanguage={handleLanguage}/>
                            <PrivacyPolicy dynamicLabels={label}></PrivacyPolicy>
                            <Footer dynamicLabels={label}/>
                        </Route>
                        <Route path={"/terms-and-conditions"}>
                            <PrimarySearchAppBar dynamicLabels={label} handleLanguage={handleLanguage}/>
                            <TermsAndConditions dynamicLabels={label}></TermsAndConditions>
                            <Footer dynamicLabels={label}/>
                        </Route>
                    </Switch>
          </div>
  );
}

export default App;
