import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const Title = styled.span`
  font-family: system-ui;
  color: white;
  font-weight: 500;
  font-size:53px;
  text-align: left;
  `

const Titlemd = styled.span`
  font-family: system-ui;
  color: white;
  font-weight: 500;
  font-size:40px;
  text-align: left;
  `

const Titlesm = styled.span`
  font-family: system-ui;
  color: white;
  font-weight: 500;
  font-size:30px;
  text-align: left;
  `


const Title2 = styled.span`
  font-family: system-ui;
  color: white;
  text-align: justify;
  `

const useStyles = makeStyles((theme) => ({
    sectionLarge: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        },
    },
    sectionMedium: {
      display: 'none',
      [theme.breakpoints.between('md','lg')]: {
          display: 'flex',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
    },
    },
    sectionMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));


export default function SimpleContainer({dynamicLabels}) {
  const classes = useStyles();

  const appLinkClick = () => {
    window.location.href = "https://play.google.com/store/apps/details?id=com.app.medhistorymadeeasy";
  }

  const appleLinkClick = () => {
    window.location.href = "https://apps.apple.com/in/app/medhistorymadeeasy/id1576337630";
  }

  return (
    <div id='home'>
      <div style={{ backgroundImage: "url(/images/Background.png)", paddingTop: 135 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Box paddingLeft={23} paddingTop={20} className={classes.sectionLarge}>
            <Title>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12}>
                  {dynamicLabels?.firstContent?.mainTitleLine1}
                </Grid>
                <Grid item lg={12} md={12}>
                  {dynamicLabels?.firstContent?.mainTitleLine2}
                </Grid>
                <Grid item lg={12} md={12}>
                  {dynamicLabels?.firstContent?.mainTitleLine3}
                </Grid>
              </Grid>
              </Title>
            </Box>
            <Box paddingLeft={18} paddingTop={20} className={classes.sectionMedium}>
            <Titlemd>
              <Grid container spacing={3}>
                <Grid item lg={12}>
                  {dynamicLabels?.firstContent?.mainTitleLine1}
                </Grid>
                <Grid item lg={12}>
                  {dynamicLabels?.firstContent?.mainTitleLine2}
                </Grid>
                <Grid item lg={12}>
                  {dynamicLabels?.firstContent?.mainTitleLine3}
                </Grid>
              </Grid>
              </Titlemd>
            </Box>
            <Box paddingLeft={7} paddingTop={20} className={classes.sectionMobile}>
            <Titlesm>
              <Grid container spacing={3}>
                <Grid item lg={12}>
                  {dynamicLabels?.firstContent?.mainTitleLine1}
                </Grid>
                <Grid item lg={12}>
                  {dynamicLabels?.firstContent?.mainTitleLine2}
                </Grid>
                <Grid item lg={12}>
                  {dynamicLabels?.firstContent?.mainTitleLine3}
                </Grid>
              </Grid>
              </Titlesm>
            </Box>
            <Box paddingLeft={23} paddingTop={5} paddingBottom={10} className={classes.sectionLarge}>
              <Title2>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  {dynamicLabels?.firstContent?.middleText}
                </Grid>
                <Box marginTop={5} marginLeft={1}>
                <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div className="app-link" onClick={appLinkClick}>
                  <img src="/images/android.png" alt="goggle play"/>
                  </div>
                </Grid>
                <Grid item xs={6}>
                <div className="app-link" onClick={appleLinkClick}>
                  <img src="/images/apple.png" alt="app store"/>
                </div>
                </Grid>
                </Grid>
                </Box>
              </Grid>
              </Title2>
            </Box>
            <Box paddingLeft={18} paddingTop={5} paddingBottom={10} className={classes.sectionMedium}>
              <Title2 style={{ fontSize: 15 }}>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  {dynamicLabels?.firstContent?.middleText}
                </Grid>
                <Box marginTop={5} marginLeft={1}>
                <Grid container spacing={3}>
                <Grid item xs={6}>
                <div className="app-link" onClick={appLinkClick}>
                <img src="/images/android.png" width={145} alt="goggle play"/>
                  </div>
                </Grid>
                <Grid item xs={6}>
                <div className="app-link" onClick={appleLinkClick}>
                  <img src="/images/apple.png" width={145} alt="app store"/>
                </div>
                </Grid>
                </Grid>
                </Box>
              </Grid>
              </Title2>
            </Box>
            <Box paddingLeft={7} paddingTop={3} paddingBottom={10}  className={classes.sectionMobile}>
              <Title2 style={{fontSize: 14}}>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  {dynamicLabels?.firstContent?.middleText}
                </Grid>
                <Box marginTop={2} marginLeft={1}>
                <Grid container spacing={3}>
                <Grid item xs={6}>
                <div className="app-link" onClick={appLinkClick}>
                  <img src="/images/android.png" width={100} alt="goggle play"/>
                  </div>
                </Grid>
                <Grid item xs={6}>
                <div className="app-link" onClick={appleLinkClick}>
                  <img src="/images/apple.png"  width={100} alt="app store"/>
                </div>
                </Grid>
                </Grid>
                </Box>
              </Grid>
              </Title2>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Box padding={6} className={classes.sectionLarge}>
              <img src="Phone.png" alt="app store" height={700} />
            </Box>
            <Box padding={6} className={classes.sectionMedium}>
              <img src="Phone.png" alt="app store" height={560} width={420}/>
            </Box>
            <Box padding={3} className={classes.sectionMobile}>
              <img src="Phone.png" alt="app store" width={320}/>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}