import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const Title = styled.span`
font-weight: bolder;
color: grey;
font-size: 16px;
`

const Titlemd = styled.span`
font-weight: bolder;
color: grey;
font-size: 15px;
`

const Titlesm = styled.span`
font-weight: bolder;
color: grey;
font-size: 14px;
`
const Title2 = styled.span`
font-weight: bolder;
font-size: 32px;
`

const Title2md = styled.span`
font-weight: bolder;
font-size: 28px;
`

const Title2sm = styled.span`
font-weight: bolder;
font-size: 22px;
`


const Title3 = styled.span`
font-family: system-ui;
text-align: left;
font-size: 18px ;
`
const Title7 = styled.span`
font-size: 1.6vw !important;
font-weight:450;
`

const Title7md = styled.span`
font-size: 22px !important;
font-weight:450;
`

const Title7sm = styled.span`
font-size: 18px !important;
font-weight:450;
`

const Titlen = styled.span`
font-weight: 300;
font-size:1.1vw;
`


const useStyles = makeStyles((theme) => ({
    sectionLarge: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        },
    },
    sectionMedium: {
      display: 'none',
      [theme.breakpoints.between('md','lg')]: {
          display: 'block',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
    },
    },
    sectionMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function Feature({dynamicLabels}) {
    const classes = useStyles();
    return (
        <div id='feature' style={{ backgroundColor : 'rgb(239,246,251)' }}>
            <Box marginTop={5} paddingTop={5} paddingBottom={8} className={classes.sectionLarge}>
                <Grid container spacing={3} alignItems="center" justify="center">
                    <Grid item xs={12}>
                        <Title>{dynamicLabels?.feauture?.topText}</Title>
                    </Grid>
                    <Grid item xs={12}>
                        <Title2>{dynamicLabels?.feauture?.titleText}</Title2>
                    </Grid>
                </Grid>
                <Box marginTop={3} paddingLeft={23}>
                    <Title3>
                        <Grid container spacing={3} alignItems="center" justify="center">
                            <Grid item xs={12} md ={6} lg={6}>
                                <Box marginBottom={5}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2}>
                                            <img src="/circle.png" alt="med history" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h2 style={{ marginTop: 0, marginBottom: 10 }}><Title7>{dynamicLabels?.feauture?.design1?.title}</Title7></h2>
                                            <Titlen>{dynamicLabels?.feauture?.design1?.content}</Titlen>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box marginBottom={5}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2}>
                                            <img src="/circle.png" alt="med history" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h2 style={{ marginTop: 0, marginBottom: 10 }}><Title7>{dynamicLabels?.feauture?.design2?.title}</Title7></h2>
                                            <Titlen>{dynamicLabels?.feauture?.design2?.content}</Titlen>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box marginBottom={5}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2}>
                                            <img src="/circle.png" alt="med history" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h2 style={{ marginTop: 0, marginBottom: 10 }}><Title7>{dynamicLabels?.feauture?.design3?.title}</Title7></h2>
                                            <Titlen> {dynamicLabels?.feauture?.design3?.content}</Titlen>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box marginBottom={5}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2}>
                                            <img src="/circle.png" alt="med history" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h2 style={{ marginTop: 0, marginBottom: 10 }}><Title7>{dynamicLabels?.feauture?.design4?.title}</Title7></h2>
                                            <Titlen>{dynamicLabels?.feauture?.design4?.content}</Titlen>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <img src="/images/mokup.png" alt="med history" />
                            </Grid>
                        </Grid>
                    </Title3>
                </Box>
            </Box>
            <Box marginTop={5} paddingTop={5} paddingBottom={8} className={classes.sectionMedium}>
                <Grid container spacing={3} alignItems="center" justify="center">
                    <Grid item xs={12}>
                        <Titlemd>{dynamicLabels?.feauture?.topText}</Titlemd>
                    </Grid>
                    <Grid item xs={12}>
                        <Title2md>{dynamicLabels?.feauture?.titleText}</Title2md>
                    </Grid>
                </Grid>
                <Box marginTop={3} paddingLeft={18}>
                    <Title3>
                        <Grid container spacing={3} alignItems="center" justify="center">
                            <Grid item xs={12} md ={6} lg={6}>
                                <Box marginBottom={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2}>
                                            <img src="/circle.png" alt="med history" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h2 style={{ marginTop: 0, marginBottom: 10 }}><Title7md>{dynamicLabels?.feauture?.design1?.title}</Title7md></h2>
                                            <Titlen style={{ fontSize: 16}}>{dynamicLabels?.feauture?.design1?.content}</Titlen>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box marginBottom={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2}>
                                            <img src="/circle.png" alt="med history" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h2 style={{ marginTop: 0, marginBottom: 10 }}><Title7>{dynamicLabels?.feauture?.design2?.title}</Title7></h2>
                                            <Titlen  style={{ fontSize: 16}}>{dynamicLabels?.feauture?.design2?.content}</Titlen>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box marginBottom={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2}>
                                            <img src="/circle.png" alt="med history" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h2 style={{ marginTop: 0, marginBottom: 10 }}><Title7>{dynamicLabels?.feauture?.design3?.title}</Title7></h2>
                                            <Titlen  style={{ fontSize: 16}}>{dynamicLabels?.feauture?.design3?.content}</Titlen>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box marginBottom={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2}>
                                            <img src="/circle.png" alt="med history" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h2 style={{ marginTop: 0, marginBottom: 10 }}><Title7>{dynamicLabels?.feauture?.design4?.title}</Title7></h2>
                                            <Titlen  style={{ fontSize: 16}}>{dynamicLabels?.feauture?.design4?.content}</Titlen>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <img src="/images/mokup.png" width={400} alt="med history" />
                            </Grid>
                        </Grid>
                    </Title3>
                </Box>
            </Box>
            <Box marginTop={5} paddingTop={5} paddingBottom={8} className={classes.sectionMobile}>
                <Grid container spacing={3} alignItems="center" justify="center">
                    <Grid item xs={12}>
                        <Titlesm>{dynamicLabels?.feauture?.topText}</Titlesm>
                    </Grid>
                    <Grid item xs={12}>
                        <Title2sm>{dynamicLabels?.feauture?.titleText}</Title2sm>
                    </Grid>
                </Grid>
                <Box marginTop={3} paddingLeft={7}>
                    <Title3>
                        <Grid container spacing={3} alignItems="center" justify="center">
                            <Grid item xs={12} md ={6} lg={6}>
                                <Box marginBottom={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2}>
                                        <Box
                                          display="flex"
                                            justifyContent="center"
                                              alignItems="center"
                                                                >
                                                    <img src="/circle.png" width={55} alt="med history" />
                                        </Box>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h2 style={{ marginTop: 0, marginBottom: 10 }}><Title7sm>{dynamicLabels?.feauture?.design1?.title}</Title7sm></h2>
                                            <Titlen style={{ fontSize: 15}}>{dynamicLabels?.feauture?.design1?.content}</Titlen>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box marginBottom={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2}>
                                        <Box
                                          display="flex"
                                            justifyContent="center"
                                              alignItems="center"
                                                                >
                                                    <img src="/circle.png" width={55} alt="med history" />
                                        </Box>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h2 style={{ marginTop: 0, marginBottom: 10 }}><Title7sm>{dynamicLabels?.feauture?.design2?.title}</Title7sm></h2>
                                            <Titlen style={{ fontSize: 15}}>{dynamicLabels?.feauture?.design2?.content}</Titlen>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box marginBottom={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2}>
                                        <Box
                                          display="flex"
                                            justifyContent="center"
                                              alignItems="center"
                                                                >
                                                    <img src="/circle.png" width={55} alt="med history" />
                                        </Box>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h2 style={{ marginTop: 0, marginBottom: 10 }}><Title7sm>{dynamicLabels?.feauture?.design3?.title}</Title7sm></h2>
                                            <Titlen style={{ fontSize: 15}}>{dynamicLabels?.feauture?.design3?.content}</Titlen>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box marginBottom={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2}>
                                        <Box
                                          display="flex"
                                            justifyContent="center"
                                              alignItems="center"
                                                                >
                                                    <img src="/circle.png" width={55} alt="med history" />
                                        </Box>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <h2 style={{ marginTop: 0, marginBottom: 10 }}><Title7sm>{dynamicLabels?.feauture?.design4?.title}</Title7sm></h2>
                                            <Titlen style={{ fontSize: 15}}>{dynamicLabels?.feauture?.design4?.content}</Titlen>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                            <Box
                                          display="flex"
                                            justifyContent="center"
                                              alignItems="center"
                                                                >
                                                    <img src="/images/mokup.png" width={318} alt="med history" />
                                        </Box>
                            </Grid>
                        </Grid>
                    </Title3>
                </Box>
            </Box>
        </div>
    )
}