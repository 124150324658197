export const spanishLabels = {
  "logo": 'images/spanish-logo.png',
  "flag": 'images/spain.png',
  "navBar" : {
    "home" : "Inicio",
    "about": "Sobre Nosotros",
    "works": "Cómo funcionav",
    "tutorial": "Tutorial",
    "contact": "Contactarnos"
  },
  "firstContent" : {
    "mainTitleLine1": "¡Simplifique y ",
    "mainTitleLine2": "mejore su",
    "mainTitleLine3": "mantenimiento de registros!",
    "middleText": ""
  },
  "aboutMed": {
    "topText": "",
    "titleText": "Sobre HistorialMedicoMasFacial",
    "mainTextP1": "Esta aplicación está diseñada para ser una ventanilla única para todos sus registros médicos. La recopilación de documentos médicos, historial, medicamentos, vacunas y más puede ser extraordinariamente engorrosa para los pacientes y sus familiares. ¡Esta plataforma ayuda a simplificar el mantenimiento de registros médicos para usted, los miembros de su familia y cualquier futuro hijo!",
    "mainTextP2": "Continúe haciendo un seguimiento con su Proveedor de atención primaria para las inquietudes relacionadas inmediatas, emergentes o agudas, pero le ayudaremos a usted y a su proveedor con el mantenimiento continuo de sus registros.",
    "mainTextP3": "Imagínese ... Nunca tendrá que preocuparse por las vacunas que ha recibido su hijo, los medicamentos que toma su abuela antes de la visita a la sala de emergencias o cuál fue el curso natural de la historia del recién nacido de su bebé.",
    "mainTextP4": "Si abandona el estado, necesita atención inmediata en una sala de emergencias o si necesita obtener un nuevo médico, esta herramienta para compartir hace que la transición sea mucho más sencilla.",
    "mainTextP5": "Este recurso no solo lo ayudará, sino que también ayudará a su proveedor de atención primaria, ya sea antiguo o recién establecido, a mantener su historial médico constantemente actualizado."
  },
  "feauture" : {
    "topText": "Características de la aplicación",
    "titleText": "Con nuestras superpotencias, hemos creado esta",
    "design1" : {
      "title": "Historia del recién nacido",
      "content": "¿Cansado de buscar entre toneladas de documentos para averiguar el historial médico de su recién nacido? Tu frustración se detiene aquí. Almacene fácilmente todos los nuevos antecedentes médicos de su recién nacido con o sin la ayuda de su doctor para facilitar las cosas."
    },
    "design2" : {
      "title": "Historial de citas",
      "content": "Nunca olvide cuándo es su próxima cita con el médico. Haremos el trabajo por usted, con nuestras notificaciones y la pestaña Historial de citas."
    },
    "design3" : {
      "title": "Mantenimiento de registros médicos",
      "content": "¿Le preocupa cómo recordar qué vacunas, historial médico, afecciones médicas o medicamentos están tomando usted o su familia? No pasa nada, siempre está aquí."
    },
    "design4" : {
      "title": "Compartir capacidades",
      "content": "¿Quiere un lugar para almacenar y compartir pruebas de sus vacunas COVID y otros antecedentes médicos? ¡Aquí tienes tu respuesta! Puede almacenar documentación médica, archivos, historial de vacunación, todo con el clic de su teléfono."
    }
  },
  "works" : {
    "topText": "HOW IT WORKS ?",
    "titleText": "With our super powers we have reached this",
    "design1" : {
      "title": "Design",
      "content": "Turn your idea from concept to MVP"
    },
    "design2" : {
      "title": "Design",
      "content": "Sketch out the product to align the user needs"
    },
    "design3" : {
      "title": "Design",
      "content": "Convert the designs into a live application"
    },
    "design4" : {
      "title": "Design",
      "content": "Launching the application to the market"
    }
  },
  "manage" : {
    "titleText": "¡Hagamos más fácil el mantenimiento de registros!",
    "middleText" : "Descargue la aplicación para que su mantenimiento de registros sea más fácil, rápido y esté disponible cuando sea necesario.",
    "appText": "Get the App"
  },
  "contact" : {
    "titleText": "Contactarnos",
    "middleText": "No dude en ponerse en contacto con nosotros para obtener más preguntas, comentarios, o preocupaciones.",
    "name1": "Su nombre",
    "name2": "Su correo electrónico",
    "message": "Mensaje",
    "password": "Password",
    "submit": "Enviar ahora"
  },
  "footer": {
    "copyright": "© Copyright 2020 HistorialMédicoMásFácil. Todos Los Derechos Reservados",
    "privacy": "la política de privacidad",
    "req": "es",
    "terms" : "Términos y condiciones"
  }
}
export default spanishLabels;